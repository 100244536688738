<template>
  <div v-if="permissions.u">
    <button-top  v-if="permissions.u" :must-icon="false" tooltip="Guardar vivero" name="Guardar" :date="form.created_at" @action="update"></button-top>
    <validation-observer ref="form">
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Nombre</label>
              <validation-provider #default="{ errors }" name="Nombre" rules="required">
                <b-form-input id="name" v-model="form.name" :state="errors.length > 0 ? false : null" name="name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="country_id" class="text-success">Pais</label>
              <validation-provider #default="{ errors }" name="Pais" rules="required">
                <b-form-select id="country_id" class="fl" v-model="form.country_id" @change="getDepartments()"
                  value-field="id" text-field="name" :options="list.countries" :state="errors.length > 0 ? false : null"
                  name="country_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="department_id" class="text-success">Departamento</label>
              <validation-provider #default="{ errors }" name="Departamento" rules="required">
                <b-form-select id="department_id" class="fl" v-model="form.department_id" @change="getCities()"
                  value-field="id" text-field="name" :options="list.departments" :state="errors.length > 0 ? false : null"
                  name="department_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="city_id" class="text-success">Municipio</label>
              <validation-provider #default="{ errors }" name="Municipio" rules="required">
                <b-form-select id="city_id" class="fl" v-model="form.city_id" value-field="id" text-field="name"
                  :options="list.cities" :state="errors.length > 0 ? false : null" name="city_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="latitude" class="text-success">Latitud</label>
              <validation-provider #default="{ errors }" name="Latitud" rules="required">
                <b-form-input id="latitude" v-model="form.latitude" :state="errors.length > 0 ? false : null"
                  name="latitude" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="longitude" class="text-success">Longitud</label>
              <validation-provider #default="{ errors }" name="Longitud" rules="required">
                <b-form-input id="longitude" v-model="form.longitude" :state="errors.length > 0 ? false : null"
                  name="longitude" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="capacity" class="text-success">Capacidad</label>
              <validation-provider #default="{ errors }" name="Capacidad" rules="required">
                <b-form-input id="capacity" v-model="form.capacity" :state="errors.length > 0 ? false : null"
                  name="capacity" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Observacion</label>
              <b-form-textarea id="observation" v-model="form.observation" name="observation" rows="3"
                max-rows="6"></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>

export default {
  data() {
    return {
      form: {
        name: '',
        country_id: '',
        department_id: '',
        city_id: '',
        latitude: '',
        longitude: '',
        capacity: '',
        observation: '',
      },

      list: {
        countries: [],
        departments: [],
        cities: [],
      },

    }
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.form.id = this.$route.params.id;
      await this.getPermission('u');
      await this.get();
    },

    async get() {
      try {
        this.loading();
        this.form = (await this.$http.get(`administration/nursery/show/${this.form.id}`)).data.data;
        this.setHeaderSubTitle(`Id. ${this.form.id} / ${this.form.name}`);
        this.list.countries = (await this.$http.get('configuration/country/index')).data.data;
        this.list.departments = (await this.$http.get(`configuration/department/show/${this.form.country_id}`)).data.data;
        this.list.cities = (await this.$http.get(`configuration/city/show/${this.form.department_id}`)).data.data;
      } catch (err) {
        if (err.response.status === 404) {
          this.$router.push({ name: 'gestion-viveros' })
        }
        this.notify("Error", err.response.data.message, 'danger')
      } finally {
        this.not_loading();
      }
    },

    async getCountries() {
      try {
        this.loading();
        this.list.countries = (await this.$http.get('configuration/country/index')).data.data;
      } catch (err) {
        this.notify("Error", err.response.data.message, 'danger')
      } finally {
        this.not_loading();
      }
    },

    async getDepartments() {
      try {
        this.loading();
        this.list.departments = (await this.$http.get(`configuration/department/show/${this.form.country_id}`)).data.data;
        this.form.department_id = '';
        this.form.city_id = '';
      } catch (err) {
        this.notify("Error", err.response.data.message, 'danger')
      } finally {
        this.not_loading();
      }
    },

    async getCities() {
      try {
        this.loading();
        this.list.cities = (await this.$http.get(`configuration/city/show/${this.form.department_id}`)).data.data;
        this.form.city_id = '';
      } catch (err) {
        this.notify("Error", err.response.data.message, 'danger')
      } finally {
        this.not_loading();
      }
    },

    async update() {
      try {
        const success = await this.$refs.form.validate();
        if (success) {
          this.loading();        
          const { message } = ( await this.$http.patch(`administration/nursery/update/${this.form.id}`, this.form)).data;
          this.notify("Proceso satisfactorio.", message, 'primary');
          setTimeout(() => {
            this.$router.push({ name: 'gestion-viveros' })
          }, 500);
        }
      } catch (err) {
        console.log(err);
        this.notify("Error", err.response.data.message, 'danger');
      } finally {
        this.not_loading();
      }
    }


  },


}
</script>